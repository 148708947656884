// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../src/pages/chi-siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-contatti-js": () => import("./../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-cookie-js": () => import("./../src/pages/cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-progettazione-js": () => import("./../src/pages/progettazione.js" /* webpackChunkName: "component---src-pages-progettazione-js" */),
  "component---src-pages-quadri-elettrici-js": () => import("./../src/pages/quadri-elettrici.js" /* webpackChunkName: "component---src-pages-quadri-elettrici-js" */),
  "component---src-pages-retrofitting-js": () => import("./../src/pages/retrofitting.js" /* webpackChunkName: "component---src-pages-retrofitting-js" */),
  "component---src-pages-software-js": () => import("./../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-teleassistenza-js": () => import("./../src/pages/teleassistenza.js" /* webpackChunkName: "component---src-pages-teleassistenza-js" */)
}

